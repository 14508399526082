.assignmentDetail-container
{
    text-align: center;
    color: black;
    background-color: #f2f7f7;
}

.assignmentDetail-container >h2{
    margin-top: 10px;
}

.assignmentDetail-container >button
{
    /*zxcxvxvv
    cxzcxzcxzv
    zxvzxv*/
    width: 100px;
    height: 35px;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
}

.assignmentDetail-container > button:hover
{
    background:#f5b44c ;
}

.topMessage{
    color:red
}

.closed-assignment-refund-quote
{
    background: #f2f7f7;
    height: 70px;

}

.closed-assignment-deductions
{
    border: 1px solid black;
}

.closed-assignment-deductions > thead, th
{
    border: 1px solid black;
}

.closed-assignment-deductions > tr, td
{
    border: 1px solid black;
}