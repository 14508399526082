.overlay-background{
    display:flex;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top:0;
    left: 0;
    cursor: pointer;
    z-index: 10;
}

.overlay-content{
    background-color: white;
    position: fixed;
    z-index: 10;
    margin: auto;
    padding: 30px;
    width: fit-content;
    height: fit-content;
    top:0;
    right:0;
    bottom:0;
    left:0;
}

.overlay-close
{
    padding: 5px;
    width: 70px;
    border-radius: 10px;
    background-color: #55a7e6;
    color:#ebf2f5;
    margin-top: 30px;
    cursor: pointer;
    font-weight: bold;
}

.overlay-close:hover
{
    background-color: #f5c85f;
    color:black;

}