.table-container
{
    margin-left: 70px;
    margin-top: 30px;
    height: 250px;
    width: 1150px;
}

thead{
    position: sticky;
    background-color: #8bd5fc ; /*#2472f0*/
    color:black;
    margin: auto;
    top: 0;
    text-align: center;
}

.assignment-table{    /*changed selector from table to class*/
    width: 100%;
    background-color: #e6e9eb;
    position: sticky;
    top: 0px;
    color:black;
    height: 100%; /*no effect*/
}

tbody{
    text-align: left
}

.assignment-table, th, td{
    border: 2px solid black;
    border-collapse: collapse;
}

th, td{
    padding: 10px;
}

.table-name {
    text-align: center;
   font-weight: bold;
}

tbody > tr{
    cursor: pointer;
}
tbody > tr:hover{
    color: blue;
    background-color: #bff2cf;
}

.currentAssignmentTitle , .closedAssignmentTitle
{
    margin-left: 382px;
    margin-top: 10px;
    color: black;
}

