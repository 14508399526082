.help-info > p{
  margin-top: 20px;
}

.help-assignment-list{
    margin-top: 10px;
    margin-left: 40px;
}

.help-title
{
    text-align: center;
}