
.assignment-detail-data-container{
    margin: 20px;
    border: none;
    height: fit-content;
    /*
    background: #f2f7f7;
    */
}

.assignment-detail-data-container > h2, h4{
    text-align: center;
}


.assignment-refund-quote tr td,
.assignment-product-refund tr td,
.assignment-detail tr td,
.deduction thead th{
   padding: 3px;
    border: none;    /*This should be removed for the border*/
}

.assignment-detail > tr > td:nth-child(1),
.assignment-product-refund > tr > td:nth-child(1),
.assignment-refund-quote > tr > td:nth-child(1)
{
    text-align: right;
    font-weight: bold;
}

.assignment-detail > tr > td:nth-child(2),
.assignment-product-refund > tr > td:nth-child(2),
.assignment-refund-quote > tr > td:nth-child(2)
{
    text-align: left;
}
/*
h4{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}*/

table{
    width: 100%;
    table-layout: fixed;
}

.current-assignment-submit , .current-assignment-cancel
{
    width: 100px;
    height:35px;
    border-radius: 20px;
    margin-left: 10px;
    margin-top: 0px;
    font-weight: bold;
    cursor: pointer;
    padding-top: 0px;
}




 .current-assignment-submit:hover
{
    background:greenyellow;
}

.current-assignment-cancel:hover
{
    background:orange;
}

 td > .deductions {
width: 100%;
 }



 .refund-quote-column{
     float: left;
     height: 310px;
 }

 .error-column{
     width: 35%;
     font-size: small;
    /* background-color: #f7c27c;*/
     color: red;
    /*# animation: errorblink 0.5s;*/
     height: 367px;
/* height: 364px; --> laptop*/
     background: #f2f7f7;
 }

 .refund-column{
     width: 65%;
 }

 .left-column{
     width: 24%;
 }


.current-assignment-submit-error
{
    width: 100px;
    height:35px;
    border-radius: 20px;
    margin-left: 10px;
    margin-top: 10px;
    font-weight: bold;
}

@keyframes errorblink {
 0%{ background-color: #ff7700;
    color:white}
    25%{background-color: white;
        color:red}
    50%{background-color: #fa4428;
        color:white}
    75%{background-color: white;
        color:red}
    100%{background-color: #f20c0c;
        color:white}
}

.error-title
{
    display: none;
}

.assignment-refund-quote
{
    background: #f2f7f7;
    height: 370px;
    width: 100%;
}

.topMessage
{
    color: red;
}

#refund-text
{
    color: red;
}