.sidebar
{
    display: flex;
    position: relative;
    margin: 80px 5px 0px;
    overflow: auto;
}

.sidebar-content
{
    min-height:85vh ; /* This helped to fill the height of the side bar, after some struggle setting this to compensate space covered by the top navigation - margin: 80px 5px 0px; */
    background: linear-gradient(to bottom, white,#004a97);
    color: black;

    flex:1;
}

.sidebar-menu > li {
    line-height: 3.8;
}

.data-container{
    flex:5;
    color:blue;
    background:#ffffff;

    margin: auto;
    min-height:85vh; /* 100% ---> has effect after altering the setting height 100% for assignment table table-container height to 100%  #d3eaf2
                       min-height:100vh saved the life */
}

.sidebar-text-2{
color: red;
}