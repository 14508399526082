.main
{
    background: #8bd5fc;
    padding:40px;
    width:65vw;
    margin: auto;
    zoom:normal;
}
.divider
{
    padding-top:12px;
}

.label
{
    padding-right: 8px;
}

.centerButton
{
    padding-top: 5px;
    width: 55px;
    margin: auto;
}

.login
{
    background: #c7edbb;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    border: 0;
    outline: 0;
}

.login:hover
{
    background: #57f794;
}
