.contact-info
{
margin-top: 20px;
}

.contact-info > ul{
    list-style: none;
    margin-left: 50px;
}

.contact-title
{
    text-align: center;
}

.contact-info-technical
{
    text-align: left;
}