.update-profile-container {
    margin: 80px;
    height: fit-content;
    position: relative;
    color: black;
    font-weight: bold;
    background-color: #f2f7f7;
}

.update-profile-container > h2,h3{
   text-align: center;
    margin: 20px;
}


.update-profile tr td{
   border: none;
}

.update-profile > tr > td > p{
  display: inline-block;
}

.update-profile  > tr > td:nth-child(1){
    text-align: right;
}

.update-profile  > tr > td:nth-child(2){
    text-align: left;
}


.update-profile-container > button
{

    width: 100px;
    height: 35px;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
}

.update{
    margin-left: 500px;
}

.cancel{
    margin-left: 50px;
}

.update:hover
{
    background-color: #57f794;
}

.cancel:hover
{
    background:#f5b44c ;
}


.separator
{
    margin-left: 20%;
    width: 70%;
}

.state-selectbox
{
    width: 27%;
}

.email-error-message,.zip-error-message
{
    color:red;
    font-weight: lighter;
    visibility: hidden;
}