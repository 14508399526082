.navbar{
  width: 100%;
  background: linear-gradient(to right, white,#004a97);
  color: black;
  padding: 4px 0;
  position: fixed;
  top:0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}

.navbar-image{
  width: 300px;
}

.navbar-menu > li {
  display: inline-block;
  list-style: none;
  margin: 5px 20px;
  font-size: 18px;
  color: white;
}


.navbar-menu > li > button{
background: #c7edbb;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  border: 0;
  outline: 0;
}

.navbar-menu > li > button:hover{
  background: #57f794;
}